<template>
    <v-card min-height="500px" class="ma-auto">
        <v-col sm="12" md="12" lg="12" class="ma-auto grey lighten-5">
            <v-img
                v-if="usuarioPartner"
                class="ma-auto"
                align-center
                justify-center
                :src="logoBvs"
                width="20vw"
                alt="logo-recuperi"
            />
            <v-img
                v-if="!usuarioPartner"
                class="ma-auto"
                align-center
                justify-center
                :src="logo"
                width="12vw"
                alt="logo-recuperi"
            />
        </v-col>
        <v-col sm="12" md="8" lg="8" class="ma-auto">
            <p class="text-justify font-weight-black mt-8">
                Olá {{ nomeDevedor }},
            </p>
            <p class="text-justify mt-8">
                Verificamos que a empresa {{ nomeExibicaoApresentante }} nos
                enviou um ou mais títulos em seu nome para cobrança via
                protesto. As informações já foram enviadas ao cartório
                responsável e você poderá ser protestado em breve.
            </p>
            <p>
                O protesto é um procedimento jurídico que formaliza a
                inadimplência, o que pode sujeitar você a diversas restrições
                relevantes, como registro nos cadastros negativos, limitação no
                acesso a crédito, contratação de serviços, entre outros.
            </p>
            <p class="text-justify">
                É muito importante que você procure empresa
                {{ nomeExibicaoApresentante }} para organizar o pagamento de
                valores em aberto.
            </p>
        </v-col>
        <v-col sm="12" md="8" lg="8" class="ma-auto text-center">
            <v-btn color="primary" class="ma-auto">
                Desejo Negociar minha dívida
            </v-btn>
            <p class="mt-6 caption text-center">
                Caso você já tenha negociado/quitado sua dívida com o
                {{ nomeExibicaoApresentante }} , fique tranquilo! Em breve, você
                receberá orientações sobre os próximos passos do seu
                procedimento de protesto por e-mail.
            </p>
            <p class="mt-6 caption text-center">
                {{ nomeExibicaoApresentante }} incentiva que seus clientes
                procurem o canal oficial de atendimento para negociar
                diretamente suas dívidas protestadas.
            </p>
            <p class="mt-6 caption text-center">
                O pagamento da presente dívida não impede o protesto de
                eventuais outros débitos ou parcelas em aberto.
            </p>
        </v-col>
        <v-col sm="12" md="12" lg="12" class="ma-auto text-center">
            <p>Obrigado, Equipe Recuperi</p>
        </v-col>
        <v-col sm="12" md="12" lg="12" class="text-center grey lighten-5">
            <p class="font-weight-light subtitle-2">
                Observação: Essa é uma prévia do e-mail enviado, utilizamos esse
                modelo para envio a todos os devedores
            </p>
            <v-btn
                small
                color="grey lighten-3"
                class="ma-auto"
                @click="fecharModal"
            >
                Fechar
            </v-btn>
        </v-col>
    </v-card>
</template>

<script>
export default {
    name: 'Template225',
    props: {
        usuarioPartner: {
            type: Boolean,
            default: false,
            required: true
        },
        nomeDevedor: {
            type: String,
            default: ''
        },
        nomeExibicaoApresentante: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            logo: require('@/assets/logo_recuperi_azul_escura.png'),
            logoBvs: require('@/assets/recuperi-boavista-email-original.png')
        };
    },
    methods: {
        fecharModal() {
            this.$emit('fecharModal', false);
        }
    }
};
</script>

<style></style>
