<template>
    <v-card min-height="500px" class="ma-auto">
        <v-col sm="12" md="12" lg="12" class="ma-auto grey lighten-5">
            <v-img
                v-if="usuarioPartner"
                class="ma-auto"
                align-center
                justify-center
                :src="logoBvs"
                width="20vw"
                alt="logo-recuperi"
            />
            <v-img
                v-if="!usuarioPartner"
                class="ma-auto"
                align-center
                justify-center
                :src="logo"
                width="12vw"
                alt="logo-recuperi"
            />
        </v-col>
        <v-col sm="12" md="8" lg="8" class="ma-auto">
            <p class="text-justify font-weight-black mt-8">
                Olá {{ nomeDevedor }},
            </p>
            <p class="text-justify mt-8">
                Verificamos que a empresa
                {{ nomeExibicaoApresentante }} enviou o comando de anuência para
                o cartório responsável por seu protesto, conforme anexo.
            </p>
            <p class="text-justify">
                Isso significa que sua dívida foi regularizada perante o
                {{ nomeExibicaoApresentante }}, porém ainda é necessário pagar
                as taxas e emolumentos do cartório responsável.
            </p>
            <v-card color="primary" class="pa-6 font-weight-bold white--text">
                <p class="text-left">
                    Importante: Para retirar o protesto e limpar seu nome, você
                    deve entrar em contato com o cartório responsável
                    (presencialmente ou por telefone), e informar que empresa
                    {{ nomeExibicaoApresentante }} enviou a “anuência eletrônica
                    via sistema C.R.A”.
                </p>
            </v-card>
            <p class="text-justify mt-6">
                Para consultar o cartório onde está seu protesto consulte no
                botão abaixo com seu CPF/CNPJ.
            </p>
            <v-col class="ma-auto text-center">
                <v-btn class="ma-auto text-center" color="primary">
                    Consultar Protestos e Cartório
                </v-btn>
            </v-col>
            <p class="text-justify caption mt-6">
                Os títulos de SP e de alguns outros estados já estão com
                disponibilidade para imprimir o boleto das custas direto no site
                da Cenprot no botão acima, desta maneira não é necessário entrar
                em contato com o cartório.
            </p>
            <p class="text-justify caption mt-6">
                O sistema da Recuperi é 100% eletrônico e não é necessário
                apresentar documentos adicionais, como a carta de anuência.
            </p>
            <p class="text-justify caption">
                É muito importante que você procure o cartório responsável pelo
                seu protesto dentro de 72 horas dessa comunicação para finalizar
                o processo de anuência e retirar o protesto de seu nome assim
                que possível.
            </p>
        </v-col>

        <v-col sm="12" md="12" lg="12" class="ma-auto text-center">
            Obrigado, Equipe Recuperi
        </v-col>
        <v-col sm="12" md="12" lg="12" class="text-center grey lighten-5">
            <p class="font-weight-light subtitle-2">
                Observação: Essa é uma prévia do e-mail enviado, utilizamos esse
                modelo para envio a todos os devedores
            </p>
            <v-btn
                small
                color="grey lighten-3"
                class="ma-auto"
                @click="fecharModal"
            >
                Fechar
            </v-btn>
        </v-col>
    </v-card>
</template>

<script>
export default {
    name: 'Template250',
    props: {
        usuarioPartner: {
            type: Boolean,
            default: false,
            required: true
        },
        nomeDevedor: {
            type: String,
            default: ''
        },
        nomeExibicaoApresentante: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            logo: require('@/assets/logo_recuperi_azul_escura.png'),
            logoBvs: require('@/assets/recuperi-boavista-email-original.png')
        };
    },
    methods: {
        fecharModal() {
            this.$emit('fecharModal', false);
        }
    }
};
</script>

<style></style>
