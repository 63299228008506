<template>
    <div id="margin-dialog-body">
        <v-dialog v-model="dialogConfirmacao" max-width="500">
            <v-card>
                <v-card-title class="headline primary--text"
                    >Deseja realmente modificar o título?</v-card-title
                >

                <v-card-text v-show="!dialogResponse"
                    >Esta modificação é definitiva e automaticamente
                    disponibilizará o título para protesto!</v-card-text
                >
                <v-card-text v-show="dialogResponse"
                    >Título modificado com sucesso!</v-card-text
                >

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        data-cy="simCorrecao"
                        color="green darken-1"
                        text
                        v-show="!dialogResponse"
                        @click="correcaoTitulo"
                        >Sim</v-btn
                    >

                    <v-btn
                        color="red darken-1"
                        text
                        v-show="!dialogResponse"
                        @click="fechaConfirmacao"
                        >Não</v-btn
                    >

                    <v-btn
                        color="green darken-1"
                        text
                        v-show="dialogResponse"
                        @click="fechaConfirmacao"
                        >Ok!</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            persistent
            transition="dialog-bottom-transition"
            v-model="loading"
            width="450px"
        >
            <div class="text-center" width="420px">
                <v-card>
                    <v-progress-circular
                        class="ma-12"
                        indeterminate
                        size="150"
                        color="primary"
                        >Carregando...</v-progress-circular
                    >
                </v-card>
            </div>
        </v-dialog>

        <v-card max-width="900px">
            <v-toolbar class="mb-5" flat dark color="primary">
                <v-btn icon dark @click="fechaConfirmacao">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Correção de Nome e Endereço</v-toolbar-title>
            </v-toolbar>
            <div class="ma-3">
                <v-card v-show="ativaCardNome" class="pa-2 mb-4">
                    <v-card-title class="mt-n4 primary--text"
                        >Nome</v-card-title
                    >
                    <v-text-field
                        outlined
                        denses
                        v-model="detalhe_titulo.nm_devedor"
                        label="Nome Devedor Atual"
                        readonly
                    ></v-text-field>
                    <v-text-field
                        data-cy="nomeAbreviado"
                        outlined
                        dense
                        v-model="detalhe_titulo.nm_devedor_cra"
                        label="Nome Devedor Abreviado"
                        :counter="45"
                        :rules="[validadorNome.required, validadorNome.min]"
                    ></v-text-field>
                </v-card>
                <v-card v-show="ativaCardEndereco" class="pa-2">
                    <v-card-title class="mt-n4 primary--text"
                        >Endereço</v-card-title
                    >
                    <v-text-field
                        outlined
                        denses
                        v-model="detalhe_titulo.ds_endereco_devedor"
                        label="Endereço Devedor Atual"
                        readonly
                    ></v-text-field>
                    <v-text-field
                        data-cy="enderecoAbreviado"
                        outlined
                        dense
                        v-model="detalhe_titulo.ds_endereco_devedor_cra"
                        label="Endereço Devedor Abreviado"
                        :counter="45"
                        :rules="[validadorNome.required, validadorNome.min]"
                    ></v-text-field>
                </v-card>

                <v-row class="pa-4 mt-0" justify="end">
                    <v-btn
                        @click="fechaConfirmacao"
                        class="ml-5"
                        dense
                        color="error"
                    >
                        Cancelar
                        <v-icon class="ml-2">cancel</v-icon>
                    </v-btn>
                    <v-btn
                        :disabled="desabilitarConfirmacao()"
                        data-cy="confirmarCorrecao"
                        @click="correcaoConfirmacao"
                        class="ml-5"
                        dense
                        color="success"
                    >
                        Confirmar
                        <v-icon class="ml-2">done</v-icon>
                    </v-btn>
                </v-row>
            </div>
        </v-card>
    </div>
</template>

<script>
import TituloService from '@/services/TituloService';
import { eventFecharModal } from '../helpers/Utilitarios';
import Vue from 'vue';

export default {
    name: 'CorrecaoTitulo',
    props: {
        detalhe_titulo: {
            type: Object,
            required: true
        },
        open: {
            type: Number,
            required: true
        }
    },
    watch: {
        localOpen: {
            deep: true,
            handler() {
                this.verificaRevisao();
            }
        }
    },
    updated() {
        this.localOpen = this.open;
    },
    beforeDestroy() {
        document.removeEventListener('keyup', this.eventFecharModal);
    },
    mounted() {
        this.localOpen = this.localOpen + 1;
        document.addEventListener('keyup', this.eventFecharModal);
    },
    data() {
        return {
            localOpen: 0,
            st_aprovacao: null,
            loading: false,
            disable: false,
            dialogResponse: false,
            dialogConfirmacao: false,
            ativaCardNome: true,
            ativaCardEndereco: true,
            validadorNome: {
                required: value => !!value || 'Requerido.',
                min: v => v.length <= 45 || 'Reduza até 45 caracteres!'
            }
        };
    },

    methods: {
        eventFecharModal,
        validarTamanhoString(string) {
            return (
                !this.detalhe_titulo[string].length ||
                this.detalhe_titulo[string].length > 45
            );
        },
        desabilitarConfirmacao() {
            if (this.ativaCardEndereco && this.ativaCardNome) {
                return (
                    this.validarTamanhoString('ds_endereco_devedor_cra') ||
                    this.validarTamanhoString('nm_devedor_cra')
                );
            }
            if (this.ativaCardEndereco) {
                return this.validarTamanhoString('ds_endereco_devedor_cra');
            }
            if (this.ativaCardNome) {
                return this.validarTamanhoString('nm_devedor_cra');
            }
            return true;
        },
        verificaRevisao() {
            this.ativaCardEndereco = true;
            this.ativaCardNome = true;
            if (this.detalhe_titulo.ds_revisao === '[ND]') {
                this.ativaCardEndereco = false;
            }
            if (this.detalhe_titulo.ds_revisao === '[ED]') {
                this.ativaCardNome = false;
            }
        },
        fechaConfirmacao() {
            this.dialogConfirmacao = false;
            this.$emit('fecharModal');
        },
        correcaoConfirmacao() {
            this.dialogConfirmacao = true;
            this.dialogResponse = false;
        },
        correcaoTitulo() {
            this.dialogConfirmacao = false;
            this.loading = true;
            let service = new TituloService(Vue.http, this.$store);
            service
                .corrigeTitulo(
                    this.detalhe_titulo.id_titulo,
                    this.detalhe_titulo.nm_devedor_cra,
                    this.detalhe_titulo.ds_endereco_devedor_cra,
                    this.detalhe_titulo.cd_status
                )
                .then(
                    response => {
                        this.historico = response.body;
                        this.loading = false;
                        this.disable = true;
                        this.$emit('fecharModal', true);
                        return this.historico;
                    },
                    error => {
                        this.mensagem = error;
                        this.loading = false;
                        this.disable = true;
                        this.$emit('fecharModal', true);
                        console.log(error);
                    }
                );
        }
    }
};
</script>
