export default class OfertaTitulo {
    constructor() {
        this.desconto = 0;
        this.desconto_porcentagem = 0;
        this.cartao = false;
        this.juros_cartao = null;
        this.parcela_cartao = null;
        this.boleto = true;
        this.juros_boleto = 0;
        this.parcela_boleto = 1;
        this.status = true;
        this.data_inicio = null;
        this.data_fim = null;
        this.message = null;
    }
}
