export default class CancelamentoService {
    constructor(http, store) {
        this._http = http;
        this._store = store;
    }

    cancelamentoTitulo(id_titulo_received, tipo_path, cd_apresentante) {
        return this._http.post(tipo_path, {
            cd_apresentante: cd_apresentante,
            id_titulo: id_titulo_received
        });
    }
}
