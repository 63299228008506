<template>
    <div v-if="titulo !== null">
        <ConfirmacaoModal
            v-if="mostrarModalConfirmacao"
            :showDialog="mostrarModalConfirmacao"
            :tituloModal="tituloModalConfirmacao"
            :textoModal="textoModalConfirmacao"
            :btnConfirmarEmit="'confirmarSolicitacao'"
            @confirmarSolicitacao="confirmarSolicitacao"
            :esconderCancelar="esconderCancelar"
            :btnConfirmar="btnConfirmarLabel"
            @fecharModal="
                mostrarModalConfirmacao = false;
                esconderCancelar = false;
            "
        />
        <v-card class="ma-3 pa-3" :loading="loading">
            <v-container>
                <p class="title">
                    <v-icon class="ma-2">mdi-information-outline</v-icon
                    >Informações do Título:
                </p>
                <v-row>
                    <v-col cols="12" sm="2" md="2" lg="2">
                        <v-text-field
                            
                            outlined
                            dense
                            hide-details
                            
                            label="Código do Status"
                            disabled
                            readonly
                        ></v-text-field>
                        
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            disabled
                            readonly
                            label="Descrição do Status"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="2" lg="2">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            disabled
                            label="Id"
                            required
                            readonly
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" lg="2">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="titulo.ds_nosso_numero"
                            label="Nosso Número"
                            required
                            disabled
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" lg="3">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="titulo.ds_numero_titulo"
                            label="Número do Título"
                            required
                            disabled
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" lg="1">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="titulo.nu_parcela"
                            label="Parcela"
                            required
                            disabled
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="3" lg="2">
                        <v-text-field
                            outlined
                            :error-messages="$v.titulo.dt_emissao.$invalid ? ['Valor do campo invalido'] : []"
                            dense
                            hide-details
                            v-model="titulo.dt_emissao"
                            label="Data de Emissão"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="3" lg="2">
                        <v-text-field
                            outlined
                            :error-messages="$v.titulo.dt_vencimento.$invalid ? ['Valor do campo invalido'] : []"
                            dense
                            hide-details
                            v-model="titulo.dt_vencimento"
                            label="Data de Vencimento"
                            required
                            
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" lg="2">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="titulo.tp_declaracao"
                            label="Tipo Declaração"
                            required
                            :error-messages="$v.titulo.tp_declaracao.$invalid ? ['Este campo é obrigatorio'] : []"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" lg="2">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="titulo.tp_endosso"
                            label="Endosso"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" lg="2">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="titulo.vl_saldo_protesto_formatado"
                            label="Valor Protestado"
                            prefix="R$"
                            required
                            
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="3" lg="2">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="titulo.vl_titulo_formatado"
                            label="Valor Total do Título"
                            prefix="R$"
                            required
                            :error-messages="$v.titulo.vl_titulo_formatado.$invalid ? ['Este campo é obrigatorio'] : []"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="3" lg="2">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="titulo.tp_aceite"
                            label="Aceite"
                            required
                            :error-messages="$v.titulo.tp_aceite.$invalid ? ['Este campo é obrigatorio'] : []"
                            
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" lg="2">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="titulo.cd_especie_titulo"
                            label="Espécie"
                            required
                            :error-messages="$v.titulo.cd_especie_titulo.$invalid ? ['Este campo é obrigatorio'] : []"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="6" lg="4">
                        <div class="d-flex">
                            
                            <v-select
                                outlined
                                dense
                                color="success"
                                :loading="loadingAgrupador"
                                :items="agrupadores"
                                v-model="titulo.ds_agrupador"
                                label="Agrupador"
                                required
                                item-text="nm_agrupador"
                                item-value="ds_agrupador"
                                data-cy="tituloAgrupador"
                                hide-details
                            />
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        v-if="titulo.vl_repassado != null"
                        cols="12"
                        sm="2"
                        md="2"
                    >
                        <v-text-field
                            disabled
                            outlined
                            dense
                            label="Valor Repasse"
                            prefix="R$"
                            hint="Valores repassados pelo cartório"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <v-card class="ma-3 pa-3" :loading="loading">
            <v-container>
                <p class="title">
                    <v-icon class="ma-2">mdi-information-outline</v-icon
                    >Informações do Devedor <b>(Sacado)</b>:
                </p>
                <v-row class="mb-n8">
                    <v-col cols="12" sm="9" md="9">
                        <v-text-field
                            :error-messages="$v.titulo.nm_devedor.$invalid ? ['Este campo é obrigatorio'] : []"
                            outlined
                            dense
                            v-model="titulo.nm_devedor"
                            label="Devedor"
                            required
                            
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="9" md="3">
                        <v-text-field
                            v-mask="['###.###.###-##','XX.XXX.XXX/XXXX-##']"
                            :error-messages="$v.titulo.nu_identificacao_devedor.$invalid ? ['Valor do campo invalido'] : []"
                            outlined
                            dense
                            v-model="titulo.nu_identificacao_devedor"
                            label="CNPJ/CPF"
                            required
                            
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mb-n8">
                    <v-col cols="12" sm="9" md="8">
                        <v-text-field
                            outlined
                            dense
                            v-model="titulo.ds_endereco_devedor"
                            :error-messages="$v.titulo.ds_endereco_devedor.$invalid ? ['Este campo é obrigatorio'] : []"
                            label="Endereço"
                            required
                            class="parcelas"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="4">
                        <v-text-field
                            outlined
                            dense
                            v-model="titulo.ds_bairro_devedor"
                            :error-messages="$v.titulo.ds_bairro_devedor.$invalid ? ['Este campo é obrigatorio'] : []"
                            label="Bairro"
                            required
                            
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mb-n8">
                    <v-col cols="12" sm="3" md="3">
                        <v-text-field
                            outlined
                            dense
                            v-model="titulo.nm_cidade_devedor"
                            :error-messages="$v.titulo.nm_cidade_devedor.$invalid ? ['Este campo é obrigatorio'] : []"
                            label="Cidade"
                            required
                            
                            class="parcelas"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="2">
                        <v-text-field
                            outlined
                            dense
                            v-maska="'#####-###'"
                            v-model="titulo.cd_cep_devedor"
                            :error-messages="$v.titulo.cd_cep_devedor.$invalid ? ['Valor do campo invalido'] : []"
                            label="CEP"
                            required
                            
                            class="parcelas"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="2">
                        <v-text-field
                            outlined
                            dense
                            v-model="titulo.cd_uf_devedor"
                            v-maska="'AA'"
                            :error-messages="$v.titulo.cd_uf_devedor.$invalid ? ['Valor do campo invalido'] : []"

                            label="UF"
                            required
                            
                            class="parcelas"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                        <v-text-field
                            outlined
                            dense
                            v-model="titulo.ds_email_devedor"
                            :error-messages="$v.titulo.ds_email_devedor.$invalid ? ['Este campo é obrigatorio'] : []"
                            label="E-mail do devedor"
                            required
                            
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="2">
                        <v-text-field
                            outlined
                            dense
                            v-model="titulo.nu_telefone_devedor"
                            :error-messages="$v.titulo.nu_telefone_devedor.$invalid ? ['Valor do campo invalido'] : []"
                            v-maska="'(##) #####-####'"
                            label="Telefone do devedor"
                            required
                            
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <v-card class="ma-3 pa-3" :loading="loading">
            <v-container>
                <p class="title">
                    <v-icon class="ma-2">mdi-information-outline</v-icon>Sacador
                </p>
                <v-row class="mb-n8">
                    <v-col cols="12" sm="9" md="9">
                        <v-text-field
                            outlined
                            dense
                            v-model="titulo.nm_sacador"
                            label="Nome do Sacador"
                            required
                            :error-messages="$v.titulo.nm_sacador.$invalid ? ['Este campo é obrigatorio'] : []"                            
                            class="parcelas"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="9" md="3">
                        <v-text-field
                            outlined
                            dense
                            v-mask="['###.###.###-##','XX.XXX.XXX/XXXX-##']"
                            :error-messages="$v.titulo.cnpj_sacador.$invalid ? ['Valor do campo invalido'] : []"
                            v-model="titulo.cnpj_sacador"
                            label="CNPJ"
                            required
                            
                        ></v-text-field>
                    </v-col>
                    
                </v-row>
                <v-row class="mb-n8">
                    <v-col cols="12" sm="9" md="8">
                        <v-text-field
                            outlined
                            dense
                            v-model="titulo.ds_endereco_sacador"
                            :error-messages="$v.titulo.ds_endereco_sacador.$invalid ? ['Este campo é obrigatorio'] : []"

                            label="Endereço"
                            required
                            class="parcelas"
                        ></v-text-field>
                    </v-col>                    
                    <v-col cols="12" sm="3" md="4">
                        <v-text-field
                            outlined
                            dense
                            v-model="titulo.nm_cidade_sacador"
                            :error-messages="$v.titulo.nm_cidade_sacador.$invalid ? ['Este campo é obrigatorio'] : []"
                            label="Cidade"
                            required
                            class="parcelas"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mb-n8">
                    <v-col cols="12" sm="3" md="2">
                        <v-text-field
                            outlined
                            dense
                            v-maska="'#####-###'"
                            v-model="titulo.cd_cep_sacador"
                            :error-messages="$v.titulo.cd_cep_sacador.$invalid ? ['Valor do campo invalido'] : []"

                            label="CEP"
                            required
                            
                            class="parcelas"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="2">
                        <v-text-field
                            outlined
                            dense
                            v-model="titulo.cd_uf_sacador"
                            :error-messages="$v.titulo.cd_uf_sacador.$invalid ? ['Valor do campo invalido'] : []"
                            v-maska="'AA'"
                            label="UF"
                            required
                            class="parcelas"
                        ></v-text-field>
                    </v-col>
                    
                </v-row>
            </v-container>
        </v-card>
        <v-card class="ma-3 pa-3" :loading="loading">
            <v-container>
                <p class="title">
                    <v-icon class="ma-2">mdi-information-outline</v-icon>Cedente
                </p>
                <v-row class="mb-n8">
                    <v-col cols="12" sm="2" md="4">
                        <v-text-field
                            outlined
                            dense
                            v-model="titulo.nm_cedente_favorecido"
                            :error-messages="$v.titulo.nm_cedente_favorecido.$invalid ? ['Este campo é obrigatorio'] : []"

                            label="Nome Cedente Favorecido"
                            required
                            
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="4"> </v-col>
                    <v-col cols="12" sm="2" md="4"> </v-col>
                </v-row>
            </v-container>
        </v-card>
        
        <v-row class="pa-4 mt-0" justify="end">
            <v-btn
                class="ml-5"
                dense
                color="error"
            >
                Cancelar
                <v-icon class="ml-2">cancel</v-icon>
            </v-btn>
            <v-btn
                :disabled="$v.titulo.$invalid"
                @click="solicitarProtesto()"
                class="ml-5"
                dense
                color="success"
            >
                Solicitar Protesto
                <v-icon class="ml-2">done</v-icon>
            </v-btn>
        </v-row>
    </div>
</template>

<script>
// import * as Formatter from '@/helpers/Formatter';
import moment from 'moment';
import TituloService from '@/services/TituloService';
// import CartorioService from '@/services/CartorioService';
import ConfirmacaoModal from '@/modals/ConfirmacaoModal';
import Vue from 'vue';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'DetalheTitulo',
    mixins: [validationMixin],
    props: {
        tituloParaReapresentacao: {
            type: Object,
            required: true
        },
        setAtualizarTitulo: {
            type: Boolean,
            required: false
        },
        mostrarConfirmacao: {
            type: Boolean,
            required: false
        },
        habilitaCorrecao: {
            type: Boolean,
            required: false
        },
        reapresentacao: {
            type: Boolean,
            required: false
        },
        showActionSalvar: Boolean
    },
    components: {
        ConfirmacaoModal
    },
    data() {
        return {
            titulo: null,
            document,
            tamanhoMaximoSomaArquivos: 5000000,
            arquivosSelecionadosTitulo: null,
            tituloModalConfirmacao: 'Confirmação',
            textoModalConfirmacao: 'Você tem certeza que deseja confirmar a solicitação de protesto?',
            btnConfirmarModalConfirmacao: 'confirmarSolicitacao',
            btnConfirmarLabel: 'Confirmar',
            tituloService: new TituloService(Vue.http, this.$store),
            alertTypeEmail: 'info',
            alertInfoEmail: 'Verificação: em andamento...',
            alertTypeCel: 'info',
            alertInfoCel: 'Verificação: em andamento...',
            cartorioEndereco: '',
            cartorioEmail: '',
            cartorioTelefone: '',
            mostrarModalConfirmacao: false,
            esconderCancelar: false,
            custodia: false,
            usuarioPartner: false,
            usuarioRecuperi: false,
            data: null,
            dataDigitavel: null,
            menu2: null,
            superUsuario: false,
            editarCartorioComarca: false,
            loadingMunicipios: null,
            iconLateral: null,
            loadingCartorios: null,
            loading: false,
            baixandoInstrumento: false,
            iconLateralCartorios: null,
            novoProtocolo: null,
            dataApontamento: null,
            novoApontamento: null,
            desabilitaCartorios: false,
            editarCartorio: false,
            itemsCartorios: [],
            instrumentoProtesto: null,
            anexoProtesto: null,
            valueCartorios: null,
            valueMunicipios: null,
            emailCheck: false,
            stEnvioCheck: false,
            telefoneCheck: false,
            loadingInstrumento: false,
            loadingAnexo: false,
            solicitaInstrumento: false,
            editandoAgrupador: false,
            agrupadorTemp: null,
            loadingAgrupador: false
        };
    },
    validations: {
        titulo: {
            nm_devedor: {required},
            dt_emissao: {required},
            dt_vencimento: {required},
            tp_declaracao: {required},
            vl_saldo_protesto_formatado: {required},
            vl_titulo_formatado: {required},
            tp_aceite: {required},
            cd_especie_titulo: {required},
            cnpj_sacador: {required, length: value => value.length === 14 || value.length === 18},
            nu_identificacao_devedor: {required, length: value => value.length === 14 || value.length === 18},
            ds_endereco_devedor: {required},
            ds_bairro_devedor: {required},
            nm_cidade_devedor: {required},
            cd_cep_devedor: {required, length: value => value.length === 9},
            cd_uf_devedor: {required, length: value => value.length === 2},
            ds_email_devedor: {required},
            nu_telefone_devedor: {required, length: value => value.length === 15},
            nm_sacador: {required},
            ds_endereco_sacador: {required},
            nm_cidade_sacador: {required},
            cd_cep_sacador: {required, length: value => value.length === 9},
            cd_uf_sacador: {required, length: value => value.length === 2},
            nm_cedente_favorecido: {required},
        },
    },
    
    
    methods:{
        confirmarSolicitacao(){
            this.mostrarModalConfirmacao = false;
            this.reapresentarTitulo();
        },
        reapresentarTitulo(){
            let payloadTitulo = {...this.titulo};
            payloadTitulo.cd_cep_devedor = payloadTitulo.cd_cep_devedor.replace(/[^a-zA-Z0-9]/g, '');
            payloadTitulo.tp_identificacao_devedor = payloadTitulo.tp_identificacao_devedor.replace(/[^a-zA-Z0-9]/g, '');
            payloadTitulo.dt_emissao = moment(payloadTitulo.dt_emissao, "DD/MM/YYYY").format('YYYY-MM-DD');
            payloadTitulo.dt_vencimento = moment(payloadTitulo.dt_vencimento, "DD/MM/YYYY").format('YYYY-MM-DD');
            payloadTitulo.cnpj_sacador = payloadTitulo.cnpj_sacador.replace(/[^a-zA-Z0-9]/g, '');
            payloadTitulo.cd_cep_sacador = payloadTitulo.cd_cep_sacador.replace(/[^a-zA-Z0-9]/g, '');
            payloadTitulo.cd_cep_sacador = payloadTitulo.cd_cep_sacador.replace(/[^a-zA-Z0-9]/g, '');
            this.$emit('reapresentarTitulo', payloadTitulo);
        },
        solicitarProtesto(){
        if(this.$v.titulo.$invalid){
            this.$v.titulo.$touch();
            return;
        }
            this.mostrarModalConfirmacao = true;
        },
        formatarTituloParaApresentacao(){
            let tituloFormatado = {
                id_titulo: this.tituloParaReapresentacao.id_titulo,
                cd_status: this.tituloParaReapresentacao.cd_status,
                ds_nosso_numero: this.tituloParaReapresentacao.ds_nosso_numero,
                ds_numero_titulo: this.tituloParaReapresentacao.ds_numero_titulo,
                nu_parcela: this.tituloParaReapresentacao.nu_parcela,
                dt_emissao: (this.tituloParaReapresentacao.dt_emissao) ? moment(this.tituloParaReapresentacao.dt_emissao).format('DD/MM/YYYY') : null,
                dt_vencimento: (this.tituloParaReapresentacao.dt_vencimento) ? moment(this.tituloParaReapresentacao.dt_vencimento).format('DD/MM/YYYY') : null,
                tp_declaracao: this.tituloParaReapresentacao.tp_declaracao,
                cd_cep_devedor: this.tituloParaReapresentacao.cd_cep_devedor,
                tp_identificacao_devedor: this.tituloParaReapresentacao.tp_identificacao_devedor,
                tp_endosso: this.tituloParaReapresentacao.tp_endosso,
                vl_saldo_protesto: this.tituloParaReapresentacao.vl_saldo_protesto,
                vl_titulo: this.tituloParaReapresentacao.vl_titulo,
                vl_titulo_formatado: this.tituloParaReapresentacao.vl_titulo_formatado,
                vl_saldo_protesto_formatado: this.tituloParaReapresentacao.vl_saldo_protesto_formatado,
                tp_aceite: this.tituloParaReapresentacao.tp_aceite,
                ds_endereco_sacador: this.tituloParaReapresentacao.ds_endereco_sacador,
                cnpj_sacador: this.tituloParaReapresentacao.cnpj_sacador,
                cd_cep_sacador: this.tituloParaReapresentacao.cd_cep_sacador,
                cd_uf_sacador: this.tituloParaReapresentacao.cd_uf_sacador,
                nm_cidade_sacador: this.tituloParaReapresentacao.nm_cidade_sacador,

                nu_identificacao_devedor: this.tituloParaReapresentacao.nu_identificacao_devedor,
                cd_especie_titulo: this.tituloParaReapresentacao.cd_especie_titulo,
                ds_agrupador: this.tituloParaReapresentacao.ds_agrupador,
                vl_repassado: this.tituloParaReapresentacao.vl_repassado,
                nm_devedor: this.tituloParaReapresentacao.nm_devedor,
                nm_devedor_cra: this.tituloParaReapresentacao.nm_devedor_cra,
                ds_endereco_devedor: this.tituloParaReapresentacao.ds_endereco_devedor,
                ds_endereco_devedor_cra: this.tituloParaReapresentacao.ds_endereco_devedor_cra,
                ds_bairro_devedor: this.tituloParaReapresentacao.ds_bairro_devedor,
                nm_cidade_devedor: this.tituloParaReapresentacao.nm_cidade_devedor,
                nu_cep_devedor: this.tituloParaReapresentacao.nu_cep_devedor,
                cd_uf_devedor: this.tituloParaReapresentacao.cd_uf_devedor,
                ds_email_devedor: this.tituloParaReapresentacao.ds_email_devedor,
                nu_telefone_devedor: this.tituloParaReapresentacao.nu_telefone_devedor,
                nm_sacador: this.tituloParaReapresentacao.nm_sacador,
                nu_documento_sacador: this.tituloParaReapresentacao.nu_documento_sacador,
                nm_cedente_favorecido: this.tituloParaReapresentacao.nm_cedente_favorecido,
                nu_documento_cedente_favorecido:
                    this.tituloParaReapresentacao.nu_documento_cedente_favorecido,
                ds_complemento: this.tituloParaReapresentacao.ds_complemento,
                ds_complemento_cra: this.tituloParaReapresentacao.ds_complemento_cra,
                ds_complemento_devedor: this.tituloParaReapresentacao.ds_complemento_devedor,
                ds_complemento_sacador: this.tituloParaReapresentacao.ds_complemento_sacador,
                // ds_complemento_c
            }
            return tituloFormatado
        }
    },
    async mounted() {
        this.titulo = this.formatarTituloParaApresentacao();
        
    
    },
    computed: {
        agrupadores() {
            return this.$store.getters.listaAgrupadores;
        },
    }
};
</script>

<style scoped></style>
