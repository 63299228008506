<template>
    <v-card min-height="500px" class="ma-auto">
        <v-col sm="12" md="12" lg="12" class="ma-auto grey lighten-5">
            <v-img
                v-if="usuarioPartner"
                class="ma-auto"
                align-center
                justify-center
                :src="logoBvs"
                width="20vw"
                alt="logo-recuperi"
            />
            <v-img
                v-if="!usuarioPartner"
                class="ma-auto"
                align-center
                justify-center
                :src="logo"
                width="12vw"
                alt="logo-recuperi"
            />
        </v-col>
        <v-col sm="12" md="8" lg="8" class="ma-auto">
            <p class="text-justify font-weight-black mt-8">
                Olá {{ nomeDevedor }},
            </p>
            <p class="text-justify mt-8">
                Verificamos que a empresa
                {{ nomeExibicaoApresentante }} solicitou o cancelamento do seu
                protesto, assumindo todos os custos dos cartórios, conforme
                anexo.
            </p>
            <p class="text-justify">
                O sistema da Recuperi é 100% eletrônico e não é necessário
                apresentar documentos adicionais, como a carta de anuência.
            </p>
            <p class="text-justify">
                Nos próximos dias, o seu protesto será retirado automaticamente.
                Você poderá acompanhar esse processo diretamente junto ao
                cartório responsável.
            </p>
        </v-col>
        <v-col sm="12" md="8" lg="8" class="ma-auto text-center">
            <v-btn color="primary" class="ma-auto">
                Ver detalhes do protesto
            </v-btn>
            <p class="mt-6 caption text-justify">
                Link disponível por 72h a partir do recebimento desse e-mail
            </p>
        </v-col>

        <v-col sm="12" md="12" lg="12" class="ma-auto text-center">
            Obrigado, Equipe Recuperi
        </v-col>
        <v-col sm="12" md="12" lg="12" class="text-center grey lighten-5">
            <p class="font-weight-light subtitle-2">
                Observação: Essa é uma prévia do e-mail enviado, utilizamos esse
                modelo para envio a todos os devedores
            </p>
            <v-btn
                small
                color="grey lighten-3"
                class="ma-auto"
                @click="fecharModal"
            >
                Fechar
            </v-btn>
        </v-col>
    </v-card>
</template>

<script>
export default {
    name: 'Template240',
    props: {
        usuarioPartner: {
            type: Boolean,
            default: false,
            required: true
        },
        nomeDevedor: {
            type: String,
            default: ''
        },
        nomeExibicaoApresentante: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            logo: require('@/assets/logo_recuperi_azul_escura.png'),
            logoBvs: require('@/assets/recuperi-boavista-email-original.png')
        };
    },
    methods: {
        fecharModal() {
            this.$emit('fecharModal', false);
        }
    }
};
</script>

<style></style>
